// navigation mob
// menu
let menuMob = document.querySelector('.navigation');

if(menuMob){
	let btnMenu = document.querySelector('.hamburger'),
		mobNavA = menuMob.querySelectorAll('a'),
		mobItem = menuMob.querySelectorAll('.menu-arr'),
		overly = document.querySelector('.overly'),
		body = document.body;

		btnMenu.addEventListener('click', function(){
			if(btnMenu.classList.contains('is_active')){
				this.setAttribute('aria-expanded', false);
				overly.style.display = "none";
			} else {
				this.setAttribute('aria-expanded', true);
				overly.style.display = "block";
			}
	
			this.classList.toggle('is_active');
			menuMob.classList.toggle('open');
			body.classList.toggle('body_scroll');
		})

		mobItem.forEach(function(el){
			let menuBtn = el.querySelector('.menu-btn');

				menuBtn.addEventListener('click', function(){
					if(el.classList.contains('open')){
						this.setAttribute('aria-expanded', false);
					} else {
						this.setAttribute('aria-expanded', true);
					}

					el.classList.toggle('open');
				});

		});
	

		overly.addEventListener('click', function(){
			this.style.display = "none";
			btnMenu.classList.remove('is_active');
			menuMob.classList.remove('open');
			body.classList.remove('body_scroll');
			btnMenu.setAttribute('aria-expanded', false);
		});


		mobNavA.forEach(function(el){
			el.addEventListener('click', function(){
				overly.style.display = "none";
				btnMenu.classList.remove('is_active');
				menuMob.classList.remove('open');
				body.classList.remove('body_scroll');
				btnMenu.setAttribute('aria-expanded', false);
			});
		});

}